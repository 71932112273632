<template>
  <div class="content">
    <div style="text-align: right">
      <el-button type="primary" @click="getPdf()">Export pdf</el-button>
    </div>
    <div id="pdfDom">
      <div class="logo">
        <img
          style="width: 90px; height: 30px"
          src="@/assets/images/logo-blue.png"
          alt=""
        />
      </div>
      <p class="text-right h1">INVOICE</p>
      <div class="title row align-items-center">
        <div class="col-sm-6 col-7">
          <div class="row">
            <div class="col-3 col-sm-2 text-right">
              <div v-if="tableData.client">CLIENT</div>
              <div v-if="tableData.email">EMAIL</div>
              <div v-if="tableData.data">DATE</div>
            </div>
            <div class="col-9 col-sm-10">
              <div>{{ tableData.client }}</div>
              <div>{{ tableData.email }}</div>
              <div>{{ tableData.data }}</div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-5 text-right">
          <div>
            JUNIOR CHAMBER INTERNATIONAL, INC. 15645 Olive Blvd, Chesterfield,
            MO USA
          </div>
          <div>TAX ID: 59-0738429</div>
        </div>
      </div>
      <div class="w-100 mt-5">
        <table style="text-align: center" class="w-100">
          <thead>
            <tr>
              <th class="w-20">SERVICE</th>
              <th>DESCRIPTION</th>
              <th>PRICE</th>
              <th>QTY</th>
              <th>TOTAL</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <!-- <td v-if="$route.query.type=='1'">Top-up</td> -->
              <td v-if="$route.query.type=='2'">Event</td>
              <!-- <td v-if="$route.query.type=='3'">Donation</td> -->
              <td v-if="$route.query.type=='4'">Meeting</td>
              <td v-if="$route.query.type=='5'">Membership Fee</td>
              <!-- <td v-if="$route.query.type=='10'">Course</td> -->
              <!-- <td v-if="$route.query.type=='11'&&$route.query.sonType=='1'">Course Paper</td>
              <td v-if="$route.query.type=='11'&&$route.query.sonType=='2'">Course Certificate</td> -->
              <td v-if="$route.query.type=='6'">Program</td>
              <td v-if="$route.query.type=='8'">Donation</td>
              <td>{{ tableData.desc }}</td>
              <!-- <td v-else>Membership fee</td> -->
              <td><span v-if="tableData.price">{{tableData.price_unit?'€':'$'}}</span>{{ tableData.price||0 }}</td>
              <td>{{ tableData.qty }}</td>
              <td><span v-if="tableData.total">{{tableData.price_unit?'€':'$'}}</span>{{ tableData.total||0 }}</td>
            </tr>
          </tbody>
        </table>
        <div
          class="text-right"
          style="height: 70px; line-height: 70px; margin-top: 80px"
        >
          SUBTOTAL: {{tableData.price_unit?'€':'$'}}{{ tableData.subtotal||0 }}
        </div>
        <div class="text-right" style="border-top: 1px solid #ccc">
          GRAND TOTAL: {{tableData.price_unit?'€':'$'}}{{ tableData.grand_total||0 }}
        </div>
        <div class="pt-5 pb-5">
          NOTICE:
          <p>
            This is a computer-generated document. No signature is required.
          </p>
        </div>

        <!-- <hr /> -->
        <div class="bottom">
          <div style="border-top: 1px solid #eee">
            Invoice was created on a computer and is valid without the signature
            and seal.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import html2Canvas from "html2canvas";
import JsPDF from "jspdf";
export default {
  data() {
    return {
      tableData: {},
    };
  },
  mounted() {
    this.invoice();
  },
  methods: {
    invoice() {
      this.$http
        .invoice({ type: this.$route.query.type, id: this.$route.query.id })
        .then((res) => {
          if (res.status == 200) {
            this.tableData = res.data;
          }
        });
    },
    // 打印发票
    getPdf() {
      let title = "invoice";
      html2Canvas(document.querySelector("#pdfDom"), {
        allowTaint: true,
        logging: false,
        useCORS: true, // 【重要】开启跨域配置
      }).then(function (canvas) {
        let contentWidth = canvas.width;
        let contentHeight = canvas.height;
        let pageHeight = (contentWidth / 592.28) * 841.89;
        let leftHeight = contentHeight;
        let position = 5;
        let imgWidth = 580;
        let imgHeight = (580 / contentWidth) * contentHeight;
        let pageData = canvas.toDataURL("image/jpeg", 1.0);
        // console.log("pageData", pageData)
        let PDF = new JsPDF("", "pt", "a4");
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, "JPEG", 10, position, imgWidth, imgHeight);
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, "JPEG", 10, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 841.89;
            if (leftHeight > 0) {
              PDF.addPage();
            }
          }
        }
        PDF.save(title + ".pdf");
      });
    },
  },
};
</script>
<style lang="less" scoped>
@media(min-width: 600px) {
  .bottom {
    width: 940px;
  }
}
@media(max-width: 600px) {
  .bottom {
    width: 100%;
  }
  .content{
    font-size: 12px;
  }
}
.content {
  background: #fff;
  padding: 15px;

  #pdfDom {
    position: relative;
    .bottom {
      position: absolute;
      text-align: center;
      // width: 940px;
      bottom: 0;
    }
  }
  tbody {
    tr:nth-child(even) {
      background: #eee;
    }
    tr:nth-child(odd) {
      background: #fff;
    }
  }
  tr {
    border-bottom: 1px solid #eee;
    height: 50px;
  }
}
</style>
